import DataGrid, {
    Column,
    ColumnChooser,
    ColumnChooserSearch,
    ColumnChooserSelection,
    Editing,
    Export,
    FilterRow,
    Grouping,
    GroupPanel,
    Item,
    MasterDetail,
    Pager,
    Paging,
    Scrolling,
    Selection,
    Sorting,
    Toolbar,
} from 'devextreme-react/data-grid';
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useMemo, useRef, useState } from 'react';
import { IDataGridColumnDescription } from '@models/response/IGridDescriptionResponse';
import { IDataGridDescriptionResponse } from '@models/response/IDataGridDescriptionResponse';
import DevExpressDataGrid, { IDevExpressDataGridOptions } from './DevExpressDataGrid';
import { IDataGridService } from '@services/IDataGridService';
import Button from '@atoms/Button';
import { MdAddchart, MdDownload, MdMenu, MdRefresh } from 'react-icons/md';
import { Template } from 'devextreme-react/core/template';
import '../styles/iproc-scheme.css';
import './DevExpressDataGridDynamic.scss';
import { IGridStateSettings } from '@models/response/IGridStateSettings';
import { IDataGridStateService } from '@services/IDataGridStateService';
import { IGridExportSettings } from '@models/response/IGridExportSettings';
import { IFilesService } from '@services/IFilesService';
import {
    applyStateSettings,
    calculateFilterExpression,
    getColumnDataTypeByFieldDataType,
    getColumnFilterOperationsByColumnDataType,
    getOriginalState,
    getStateSettings,
    onCellHoverChanged,
    updateModeOff,
    updateModeOn,
} from '@utils/dataGridUtils';
import ActionButton from './Buttons/ActionButton';
import { IActionService } from '@services/actions/IActionService';
import { IBaseAction } from '@models/actions/IBaseAction';
import { getDocIdFromLinkedDocId, simulateMouseClick } from '@utils/helpers';
import GroupActionButton, { IGroupActionButtonRefActions } from './GroupActionButton';
import DisplayField from '@atoms/DisplayField/DisplayField';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@atoms/Tooltip';
import dxDataGrid, {
    CellPreparedEvent,
    OptionChangedEvent,
    RowPreparedEvent,
    SelectionChangedEvent,
} from 'devextreme/ui/data_grid';
import DataSource from 'devextreme/data/data_source';
import DocumentFormDetails from '@atoms/DevExpress/DataGrid/Details/DocumentFormDetails/DocumentFormDetails';
import GridDetails from '@atoms/DevExpress/DataGrid/Details/GridDetails/GridDetails';
import { IDocumentModalState } from '@/store/reducers/DocumentReducer';
import StateModal from '@organisms/StateModal/StateModal';
import { exportGridToExcel } from '@utils/devextreme-react/dataGridUtils';

export interface IDevExpressDataGridDynamicOptions extends IDevExpressDataGridOptions {
    service: IDataGridService;
    onRowClick?: (e: any) => void;
    onRowPrepared?: (e: any) => void;
    onContextMenuPreparing?: (e: any) => void;
    gridKey: string;
    stateService: IDataGridStateService;
    filesService: IFilesService;
    actionService?: IActionService<IBaseAction>;
    toolbarItems?: JSX.Element[];
    customTemplates?: JSX.Element[];
    linkPrefix?: string;
    isExternalDescription?: boolean;
    externalDescription?: IDataGridDescriptionResponse;
}

export type IDxDataGridDynamicHandle = {
    refresh: () => void;
    getGridRef: () => React.RefObject<DataGrid<any, any>>;
};

const allowedPageSizes = [5, 10, 25];

const DevExpressDataGridDynamic = forwardRef<IDxDataGridDynamicHandle, IDevExpressDataGridDynamicOptions>(
    (
        {
            service,
            onRowClick,
            onRowPrepared: onRowPreparedProp,
            onContextMenuPreparing,
            gridKey,
            stateService,
            filesService,
            actionService,
            toolbarItems = [],
            customTemplates,
            linkPrefix,
            isExternalDescription = false,
            externalDescription = undefined,
        }: IDevExpressDataGridDynamicOptions,
        ref: React.Ref<IDxDataGridDynamicHandle>,
    ) => {
        const [loadedGridDescription, setLoadedGridDescription] = useState<IDataGridDescriptionResponse>();
        const [currentGridDescription, setCurrentGridDescription] = useState<IDataGridDescriptionResponse>();
        const [showStateModal, setShowStateModal] = useState<boolean>(false);
        const [stateData, setStateData] = useState<IGridStateSettings[]>();
        const activated = React.useRef(false);
        const controlRef = useRef<IGroupActionButtonRefActions>(null);
        const [localLoadOptions, setLocalLoadOptions] = useState<any>();
        const gridRef = useRef<DataGrid>(null);
        const isGridUpdating = useRef<boolean>(false);

        const navigate = useNavigate();

        const grid = () => {
            return gridRef.current?.instance;
        };

        const hasDetailsGrid = () => {
            return (
                currentGridDescription?.options.isMaster &&
                (currentGridDescription.detailsForm || currentGridDescription.detailsGrid)
            );
        };

        useImperativeHandle(ref, () => ({
            refresh() {
                grid()?.refresh();
            },
            getGridRef() {
                return gridRef;
            },
        }));

        function getRowFields(link: IDocumentModalState) {
            let rowFields: Record<string, any> = {};
            gridRef.current?.instance.getVisibleColumns().forEach((column) => {
                const dataField = column.dataField;

                if (
                    !dataField ||
                    !loadedGridDescription ||
                    dataField === 'actionColumn' ||
                    dataField === 'emptyColumn'
                ) {
                    return;
                }

                const columnDescription = loadedGridDescription.columns.find((col) => col.field === dataField);

                if (!columnDescription) return;

                const field = link.data.fields.find((f) => f.name === columnDescription.docRequisite);

                if (field) {
                    rowFields[columnDescription.fieldKey] = field.value || '';
                }
            });

            return rowFields;
        }

        const buildDataSource = (
            keyGetter: () => string,
            gridGetter: () => dxDataGrid | undefined,
            editing: () => boolean,
        ) => {
            return new DataSource<any, string>({
                key: keyGetter(),
                ...(editing() && {
                    update: (key: any, values: any) => {
                        return service.putData({ key: key, values: values, runHook: false }).then((res) => {
                            return res.data;
                        });
                    },
                }),
                load(options: any) {
                    setLocalLoadOptions(options);

                    let columnsIndexes: string[] = [];
                    gridGetter()
                        ?.getVisibleColumns()
                        ?.forEach((column) => {
                            if (
                                column?.visibleIndex == null ||
                                column.dataField == 'actionColumn' ||
                                column.dataField == 'emptyColumn' ||
                                column.type == 'selection'
                            ) {
                                return;
                            }

                            columnsIndexes.push(((column as any).index + 1).toString());
                        });

                    if (columnsIndexes.length === 0) return [];

                    let loadOptions = {
                        options: options,
                        fields: columnsIndexes.join(','),
                    };

                    return service.fetchData(gridKey, loadOptions).then((res) => {
                        return res.data;
                    });
                },
                requireTotalCount: true,
            });
        };

        const resetState = () => {
            grid()?.state(null);
        };

        const resetDataSource = () => {
            grid()?.option('dataSource', undefined);
        };

        const createDataSource = useCallback(() => {
            if (loadedGridDescription) {
                grid()?.option(
                    'dataSource',
                    buildDataSource(
                        () => loadedGridDescription.dataSource.store.key,
                        () => grid(),
                        () => loadedGridDescription.columns.some((x) => x.allowEdit === true),
                    ),
                );
            }
        }, [loadedGridDescription]);

        useEffect(() => {
            activated.current = true;
            return () => {
                activated.current = false;
            };
        }, []);

        /**
         * Сброс по изменению темплейта
         */
        useEffect(() => {
            if (gridKey) {
                //resetDataSource();
                setLoadedGridDescription(undefined);
                setStateData(undefined);
            }
        }, [gridKey]);

        /**
         * Получение пользовательских настроек
         */
        useEffect(() => {
            if (gridKey) {
                stateService.fetchState(gridKey).then((response) => {
                    if (activated.current) {
                        setStateData(response.data.length > 0 ? response.data : []);
                    }
                });
            }
        }, [gridKey]);

        /**
         * Получение схемы грида (внешний обработчик)
         */
        useEffect(() => {
            if (isExternalDescription) {
                if (externalDescription) {
                    setLoadedGridDescription(externalDescription);
                }
            }
        }, [externalDescription]);

        /**
         * Получение схемы грида (внутренний обработчик)
         */
        useEffect(() => {
            if (!isExternalDescription) {
                if (gridKey) {
                    service.fetchScheme(gridKey).then((res) => {
                        if (activated.current) {
                            setLoadedGridDescription(res.data);
                        }
                    });
                }
            }
        }, [gridKey]);

        useEffect(() => {
            if (loadedGridDescription) {
                setCurrentGridDescription(loadedGridDescription);
            }
        }, [loadedGridDescription]);

        /**
         * TODO Fix W1005
         */
        useEffect(() => {
            if (loadedGridDescription && currentGridDescription) {
                updateModeOn(grid(), isGridUpdating);
                resetState();
                createDataSource();
                //updateModeOff(grid(), isGridUpdating);
            }
        }, [loadedGridDescription, currentGridDescription]);

        /**
         * Обновление пользовательского состояния при загрузке и при выборе из модального окна
         */
        useEffect(() => {
            if (loadedGridDescription && currentGridDescription && stateData) {
                updateModeOn(grid(), isGridUpdating);
                applyStateSettings(grid(), getStateSettings(stateData));
                updateModeOff(grid(), isGridUpdating);
            }
        }, [loadedGridDescription, currentGridDescription, stateData]);

        const onClickRefresh = () => {
            updateModeOn(grid(), isGridUpdating);
            applyStateSettings(grid(), getOriginalState(loadedGridDescription));
            updateModeOff(grid(), isGridUpdating);
        };

        const onClickStateChooser = () => {
            setShowStateModal(true);
        };

        const onClickShowColumns = (e: any) => {
            grid()?.showColumnChooser();
        };

        const onClickExport = () => {
            let columns: IGridExportSettings[] = [];

            gridRef.current?.instance.getVisibleColumns()?.forEach((column) => {
                if (
                    column?.visibleIndex == null ||
                    column.dataField == 'actionColumn' ||
                    column.dataField == 'emptyColumn' ||
                    column.type == 'selection'
                )
                    return;

                if ((column?.visibleIndex ?? -1) >= 0) {
                    columns.push({
                        dataField: column.dataField,
                        dataType: column.dataType,
                        name: column.caption,
                        visible: column.visible,
                        visibleIndex: column.visibleIndex,
                        width: column.width,
                    });
                }
            });

            const options = localLoadOptions
                ? localLoadOptions
                : gridRef.current?.instance.getDataSource().loadOptions();
            gridRef.current?.instance.beginCustomLoading('Экспортировать данные');
            service.exportData(gridKey, options, columns).then((response) => {
                filesService.downloadFileByGuid(response.data.fileGuid, response.data.fileName, () => {
                    gridRef.current?.instance.endCustomLoading();
                });
            });
        };

        const onEditorPreparing = (e: any) => {
            if (e.parentType === 'filterRow') {
                e.editorOptions.onEnterKey = function () {
                    // применение фильтра по нажатию Enter
                    simulateMouseClick(e.element.querySelector('.dx-apply-button')!);
                };
            }
        };

        // -------------------------------------------------------------------------------------------------------------------
        // Пользовательские скрипты
        // -------------------------------------------------------------------------------------------------------------------

        const [onRowPreparedScript, setOnRowPreparedScript] = useState<string>();
        const [onCellPreparedScript, setOnCellPreparedScript] = useState<string>();

        useEffect(() => {
            setOnRowPreparedScript(undefined);
            if (currentGridDescription?.options.onRowPrepared) {
                fetch(currentGridDescription?.options.onRowPrepared).then((value) => {
                    console.log(`onRowPrepared. Загружен скрипт: ${currentGridDescription?.options.onRowPrepared}`);
                    const contentType = value.headers.get('Content-Type');
                    if (contentType === 'text/javascript' || contentType === 'application/javascript') {
                        value.text().then((value) => {
                            setOnRowPreparedScript(value);
                        });
                    } else {
                        console.log(
                            `onRowPrepared. Файл ${currentGridDescription?.options.onRowPrepared} не является JS файлом (ContentType: ${contentType}). Скрипт не будет исполнен.`,
                        );
                    }
                });
            }

            setOnCellPreparedScript(undefined);
            if (currentGridDescription?.options.onCellPrepared) {
                fetch(currentGridDescription?.options.onCellPrepared).then((value) => {
                    console.log(`onCellPrepared. Загружен скрипт: ${currentGridDescription?.options.onCellPrepared}`);
                    const contentType = value.headers.get('Content-Type');
                    if (contentType === 'text/javascript' || contentType === 'application/javascript') {
                        value.text().then((value) => {
                            setOnCellPreparedScript(value);
                        });
                    } else {
                        console.log(
                            `onCellPrepared. Файл ${currentGridDescription?.options.onCellPrepared} не является JS файлом (ContentType: ${contentType}). Скрипт не будет исполнен.`,
                        );
                    }
                });
            }
        }, [currentGridDescription?.options.onRowPrepared, currentGridDescription?.options.onCellPrepared]);

        const onRowPrepared = async (row: RowPreparedEvent<any, string>) => {
            if (row.rowType === 'data' && onRowPreparedScript) {
                try {
                    eval(onRowPreparedScript);
                } catch (error) {
                    console.log('onRowPrepared. Ошибка выполнения скрипта. Подробности ниже.');
                    console.error(error);
                }
            }
            onRowPreparedProp && onRowPreparedProp(row);
        };

        const onCellPrepared = async (cell: CellPreparedEvent<any, string>) => {
            if (cell.rowType === 'data') {
                // записываем значение ячейки в кастомный data-value атрибут ячейки
                if (!cell.cellElement.getAttribute('data-value')) {
                    cell.cellElement.setAttribute('data-value', cell.text);
                }
            }

            if (cell.rowType === 'data' && onCellPreparedScript) {
                try {
                    eval(onCellPreparedScript);
                } catch (error) {
                    console.log('onCellPrepared. Ошибка выполнения скрипта. Подробности ниже.');
                    console.error(error);
                }
            }
        };

        // -------------------------------------------------------------------------------------------------------------------

        const onSelectionChanged = (e: SelectionChangedEvent<any, string>) => {
            let keys = e.selectedRowKeys;
            let data = e.selectedRowsData;
            if (keys.length > 0) {
                controlRef.current?.setObjData(keys.join(','), data);
            } else {
                controlRef.current?.setObjData('', data);
            }
        };

        const onOptionChanged = (e: OptionChangedEvent<any, string>) => {
            /**
             * Обновление данных грида при добавлении столбцов
             */
            if (e.name === 'columns') {
                if (e.fullName.endsWith('.visible') && e.value === true) {
                    e.component.refresh();
                }
            }
        };

        const refreshGrid = () => {
            grid()?.refresh();
        };

        const onGroupActionComplete = () => {
            refreshGrid();
            grid()?.deselectAll();
        };

        const getGridColumnFromDescription = (description: IDataGridColumnDescription, key: string): JSX.Element => {
            return (
                <Column
                    key={key}
                    /* Настройка width ломает настройку грида columnResizingMode="widget" */
                    width={description.width ? description.width : undefined}
                    minWidth={description.width.toString().endsWith('vw') ? 5 : description.minWidth}
                    allowFiltering={description.allowFiltering}
                    caption={description.header}
                    dataField={description.field}
                    dataType={getColumnDataTypeByFieldDataType(description.dataType)}
                    alignment={description.alignment}
                    showInColumnChooser={description.showInColumnChooser}
                    visible={!description.defaultHide}
                    allowGrouping={description.allowGrouping}
                    allowEditing={description.allowEdit}
                    sortIndex={description.sortIndex ?? undefined}
                    sortOrder={description.sortOrder}
                    //colIndexId={ description.colIndexId}
                    //allowFiltering={description.dataField !== "actionColumn"}
                    allowReordering={!(description.field === 'actionColumn' || description.field === 'emptyColumn')}
                    allowSorting={!(description.field === 'actionColumn' || description.field === 'emptyColumn')}
                    filterOperations={getColumnFilterOperationsByColumnDataType(
                        getColumnDataTypeByFieldDataType(description.dataType),
                    )}
                    // фиксируем колонку действий, только если нет детализации строк, иначе ломается верстка таблицы при разворачивании подчиненных строк
                    fixed={description.field === 'actionColumn' && !hasDetailsGrid()}
                    fixedPosition={'right'}
                    calculateFilterExpression={calculateFilterExpression}
                    encodeHtml={true}
                    cssClass={description.cssClasses.join(' ')}
                    cellTemplate={
                        description.dxGridCellTemplate
                            ? description.dxGridCellTemplate
                            : description.field === 'actionColumn'
                              ? 'dxGridRowMenuCellTemplateAjax'
                              : 'dxGridFieldCellTemplate'
                    }
                />
            );
        };

        const mainGridColumns = useMemo(() => {
            if (currentGridDescription && currentGridDescription.columns) {
                return currentGridDescription.columns.map((description, i) =>
                    getGridColumnFromDescription(description, `col_${i}`),
                );
            } else {
                return [] as JSX.Element[];
            }
        }, [currentGridDescription]);

        const DetailSection = (param: any) => {
            if (currentGridDescription?.detailsForm) {
                return (
                    <DocumentFormDetails
                        docId={getDocIdFromLinkedDocId(param.data.key)}
                        detailsForm={currentGridDescription.detailsForm}
                    />
                );
            }

            if (currentGridDescription?.detailsGrid) {
                return (
                    <GridDetails
                        key={param.data.key}
                        scheme={currentGridDescription?.detailsGrid[0]}
                        gridRef={gridRef}
                        service={service}
                        data={gridKey}
                        onOptionChanged={onOptionChanged}
                        detailSection={DetailSection}
                    />
                );
            }

            return <></>;
        };

        const onCellClick = (e: any) => {
            if (e.column && e.column.name == 'actionColumn') {
                e.event?.stopImmediatePropagation();
            }
        };

        const onMainRowClick = useCallback(
            (e: any) => {
                if (!currentGridDescription?.options.isMaster) {
                    onRowClick?.(e);
                }
            },
            [currentGridDescription],
        );

        const onExporting = () => {
            exportGridToExcel(gridRef, 'Экспорт');
        };

        // -------------------------------------------------------------------------------------------------------------------

        const gridControl = (
            <DevExpressDataGrid
                filterSyncEnabled={true}
                remoteOperations={true}
                hoverStateEnabled={true}
                columnHidingEnabled={false}
                columnMinWidth={30}
                showColumnHeaders={currentGridDescription?.options.showColumnHeaders}
                columnAutoWidth={currentGridDescription?.options.columnAutoWidth}
                allowColumnReordering={currentGridDescription?.options.allowColumnReordering}
                allowColumnResizing={currentGridDescription?.options.allowColumnResizing}
                columnResizingMode="widget"
                noDataText={currentGridDescription?.options.noDataText}
                rowAlternationEnabled={currentGridDescription?.options.rowAlternationEnabled}
                wordWrapEnabled={currentGridDescription?.options.wordWrapEnabled}
                onCellClick={onCellClick}
                onRowClick={onMainRowClick}
                onContextMenuPreparing={onContextMenuPreparing}
                ref={gridRef}
                className={currentGridDescription?.options.cssClasses.join(' ')}
                onRowPrepared={onRowPrepared}
                onEditorPreparing={onEditorPreparing}
                onSelectionChanged={onSelectionChanged}
                onCellHoverChanged={onCellHoverChanged}
                onOptionChanged={onOptionChanged}
                onCellPrepared={onCellPrepared}
                onExporting={onExporting}
            >
                {mainGridColumns}

                {hasDetailsGrid() && <MasterDetail enabled={true} render={DetailSection} />}
                {customTemplates}
                <Template
                    key="template_dxGridFieldCellTemplate"
                    name="dxGridFieldCellTemplate"
                    //js.devexpress.com/Documentation/ApiReference/UI_Components/dxDataGrid/Configuration/#onCellPrepared
                    render={useCallback(
                        (data: any) => {
                            let { value } = data;

                            let key = '';
                            if (data.rowType === 'data') {
                                if (data.data.documentKey) {
                                    key = data.data.documentKey;
                                } else if (data.data.key) {
                                    key = data.data.key;
                                }
                            }

                            // Для корректного отображения переносов строк заменяем переносы каретки на аналог из html
                            if (typeof value === 'string') {
                                value = value.replaceAll('\n', '<br />');
                            }

                            let col = currentGridDescription?.columns?.find((x) => x.field === data.column.dataField);
                            let ids = key.split('/');
                            let linkedDocId = ids.length > 1 ? ids[1] : ids[0];

                            let className = col?.trimLongText ? 'trim-long-text' : undefined;

                            return linkPrefix && linkedDocId !== '-1' && currentGridDescription?.options.urlEnabled ? (
                                <a
                                    href={`${linkPrefix}/${linkedDocId}`}
                                    onClick={(e) => {
                                        e.preventDefault();
                                        e.stopPropagation();

                                        navigate(`${linkPrefix}/${linkedDocId}`);
                                    }}
                                >
                                    {' '}
                                    <DisplayField className={className} type={col?.dataType!} value={value} />
                                </a>
                            ) : (
                                <DisplayField className={className} type={col?.dataType!} value={value} />
                            );
                        },
                        [currentGridDescription],
                    )}
                />

                <Template
                    key="template_dxGridRowMenuCellTemplateAjax"
                    name="dxGridRowMenuCellTemplateAjax"
                    render={useCallback((data: any) => {
                        let dataGrid = data['component'] as dxDataGrid;
                        let key = data['key'];

                        /*return <DevExpressButton icon="more" onClick={onRowMenuClick}></DevExpressButton>*/
                        return (
                            <ActionButton
                                objId={key}
                                service={actionService}
                                objData={data.data}
                                onModifyData={refreshGrid}
                            />
                        );
                    }, [])}
                />

                <Selection
                    allowSelectAll={currentGridDescription?.selection.allowSelectAll}
                    mode={currentGridDescription?.selection.mode}
                    selectAllMode={currentGridDescription?.selection.selectAllMode}
                    showCheckBoxesMode={currentGridDescription?.selection.showCheckBoxesMode}
                />

                <ColumnChooser enabled={true} mode={'select'} height={600}>
                    <ColumnChooserSelection allowSelectAll={true} selectByClick={true} />
                    <ColumnChooserSearch enabled={true} />
                </ColumnChooser>

                <FilterRow
                    visible={currentGridDescription?.options?.allowFiltersRow}
                    applyFilter={'onClick'} //{gridDescription?.filterRow.applyFilter}
                />

                <GroupPanel
                    visible={currentGridDescription?.groupPanel.visible}
                    allowColumnDragging={currentGridDescription?.groupPanel.allowColumnDragging}
                />

                <Grouping autoExpandAll={currentGridDescription?.groupPanel.visible} />

                <Pager
                    showInfo={true}
                    showPageSizeSelector={currentGridDescription?.pager.showPageSizeSelector}
                    visible={true}
                    showNavigationButtons={true}
                    displayMode={'full'}
                    allowedPageSizes={
                        currentGridDescription?.pager.pagerSizes
                            ? currentGridDescription?.pager.pagerSizes
                            : allowedPageSizes
                    }
                />

                <Paging
                    enabled={currentGridDescription?.paging.enabled}
                    defaultPageSize={currentGridDescription?.paging.pageSize}
                />

                <Scrolling mode={currentGridDescription?.scrolling.mode} />

                <Export enabled={true} allowExportSelectedData={true} formats={['xlsx']} />

                <Sorting mode="multiple" />

                <Toolbar>
                    <Item location="before">
                        {/*кнопка групповых действий*/}
                        <GroupActionButton
                            gridRef={gridRef}
                            controlRef={controlRef}
                            service={actionService}
                            onModifyData={onGroupActionComplete}
                        />
                    </Item>

                    <Item name="groupPanel" location="before" visible={currentGridDescription?.groupPanel.visible} />

                    {toolbarItems}

                    <Item name="applyFilterButton" location="after" visible={true} />

                    <Item location="after" visible={currentGridDescription?.options.ods_format}>
                        <Tooltip openDelay={100} background="black" position="top">
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xxs"
                                aria-label="Экспортировать данные в формате ODS"
                                onClick={onClickExport}
                                startAdornment={<MdDownload size="18" />}
                            />
                            Экспортировать данные в формате ODS
                        </Tooltip>
                    </Item>

                    <Item location="after" visible={currentGridDescription?.options.excel_format}>
                        <Tooltip openDelay={100} background="black" position="top">
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xxs"
                                aria-label="Экспортировать данные в формате .xls"
                                onClick={onClickExport}
                                startAdornment={<MdDownload size="18" />}
                            />
                            Экспортировать данные в формате .xls
                        </Tooltip>
                    </Item>

                    <Item location="after">
                        <Tooltip openDelay={100} background="black" position="top">
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xxs"
                                aria-label="Список состояний"
                                onClick={onClickStateChooser}
                                startAdornment={<MdMenu size="18" />}
                            />
                            Список состояний
                        </Tooltip>
                    </Item>

                    <Item location="after">
                        <Tooltip openDelay={100} background="black" position="top">
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xxs"
                                aria-label="Сбросить состояние"
                                onClick={onClickRefresh}
                                startAdornment={<MdRefresh size="18" />}
                            />
                            Сбросить состояние
                        </Tooltip>
                    </Item>

                    <Item location="after" visible={currentGridDescription?.options.allowColumnChooser}>
                        <Tooltip openDelay={100} background="black" position="top">
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xxs"
                                aria-label="Выбор столбцов"
                                onClick={onClickShowColumns}
                                startAdornment={<MdAddchart size="18" />}
                            />
                            Выбор столбцов
                        </Tooltip>
                    </Item>

                    <Item name="saveButton" location="after" />
                </Toolbar>
                {currentGridDescription?.columns.some((x) => x.allowEdit === true) && (
                    <Editing mode="batch" newRowPosition="last" refreshMode="full" allowUpdating={true} />
                )}
            </DevExpressDataGrid>
        );

        return (
            <>
                <StateModal
                    stateKey={gridKey}
                    show={showStateModal}
                    stateSettings={stateData}
                    onCloseClick={() => setShowStateModal(false)}
                    onSelectState={(stateData: IGridStateSettings[] | undefined) => {
                        setStateData(stateData);
                        setShowStateModal(false);
                    }}
                    componentRef={gridRef}
                    stateService={stateService}
                />
                <div className={'dxGrid-wrapper'}>{gridControl}</div>
            </>
        );
    },
);

export default DevExpressDataGridDynamic;
