import React, { useEffect, useState } from 'react';
import Button from '@atoms/Button';
import Modal from '@atoms/Modal';
import { MdClose } from 'react-icons/md';
import { IBaseAction } from '@models/actions/IBaseAction';
import { classnames } from '@utils/classnames';
import { DocBaseActionExecutor } from '@utils/actions/DocBaseActionExecutor';
import Preloader from '@atoms/Preloader';
import { DocumentExecutionService } from '@services/actions/DocumentExecutionService';
import Hint from '@atoms/Hint';
import { FilesService } from '@services/FilesService';
import { ModalSize } from '@atoms/Modal/Modal';
import { ICreateReportModel } from '@models/document/actionsExecution/ICreateReportModel';

export class CreateReportExecutor extends DocBaseActionExecutor {
    private _modalProps?: ICreateReportExecutorProps;

    runInternal = (
        objId: string,
        parentId: string | undefined,
        action: IBaseAction,
        rowData?: any,
        completeHandler?: (isSucceed: boolean) => void,
        modalSize?: ModalSize | null,
    ) => {
        this._modalProps = {
            docId: objId ?? '',
            actionKey: action.key ?? '',
            title: action.options?.title ?? '',
            okButtonText: action.options?.okButtonText ?? 'ОК',
            cancelButtonText: action.options?.cancelButtonText ?? 'Отмена',
            modalSize: modalSize ?? action.options?.modalSize ?? 'xl',
            completeHandler: completeHandler,
        };
    };

    visualElementInner = () => {
        return this._modalProps ? <CreateReportExecutorModal {...this._modalProps} /> : <div></div>;
    };
}

interface ICreateReportExecutorProps {
    docId: string;
    actionKey: string;
    title: string;
    okButtonText: string;
    cancelButtonText: string;
    modalSize: ModalSize;
    completeHandler?: (isSucceed: boolean) => void;
}

const CreateReportExecutorModal: React.FC<ICreateReportExecutorProps> = (props: ICreateReportExecutorProps) => {
    const [errorText, setErrorText] = useState<string>();
    const [isBusy, setIsBusy] = useState<boolean>();

    const [model, setModel] = useState<ICreateReportModel>();
    const service = new DocumentExecutionService(props.docId);
    const filesService = new FilesService();

    useEffect(() => {
        setIsBusy(true);
        service
            .getCreateReportModel(props.actionKey)
            .then((res) => {
                setModel(res.data);
            })
            .catch((error) => setErrorText(error))
            .finally(() => {
                setIsBusy(false);
            });
    }, []);

    return (
        <Modal
            className={classnames('modal-dlg-container')}
            size={props.modalSize}
            header={
                <>
                    <div className={classnames('box')}>
                        <div>{props.title}</div>
                        <div className={classnames('left')}>
                            <Button
                                buttonType="text"
                                textColor="neutral"
                                size="xs"
                                aria-label="Закрыть окно"
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                                startAdornment={<MdClose size="24" />}
                            />
                        </div>
                    </div>
                </>
            }
        >
            {isBusy ? (
                <Preloader size="m" />
            ) : (
                <div>
                    {errorText && <Hint icon="info" title={`Ошибка: ${errorText}`} variant="red" maxWidth={'100%'} />}

                    <div className="modal-dlg-buttons">
                        <div className={'left'}>
                            <Button
                                size="s"
                                aria-label={props.okButtonText}
                                onClick={() => {
                                    setIsBusy(true);

                                    if (model) {
                                        filesService.downloadFile(
                                            `documentExecution/downloadReportDoc/${props.docId}/${props.actionKey}/`,
                                        );
                                        props.completeHandler && props.completeHandler(false);
                                    } else {
                                        setErrorText('Не удалось получить модель данных.');
                                        setIsBusy(false);
                                    }
                                }}
                            >
                                {props.okButtonText}
                            </Button>

                            <Button
                                buttonType="light"
                                size="s"
                                aria-label={props.cancelButtonText}
                                onClick={() => {
                                    if (props.completeHandler) props.completeHandler(false);
                                }}
                            >
                                {props.cancelButtonText}
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Modal>
    );
};
